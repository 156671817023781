import { useEffect, useRef } from 'react';
import Card from 'react-bootstrap/Card';
import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';

import { useCollections } from 'context/collectionsContext';

import Button from 'common/button/Button';
import Icon from 'common/Icon/Icon';

import CardHeader from './CardHeader';
import PostContent from './PostContent';
import { IsEditWrapper, StyledCard } from './styles';

const Post = ({
  post,
  openContributionDetails,
  hideActions,
  withSelectIndicator,
  selected,
  onClick,
  bookmarkId,
  menuItems,
  hideFeedPost,
  onAlertUpdate,
  onProfileNavigate,
}) => {
  const { removeItemFromCollection } = useCollections();
  const postRef = useRef();

  useEffect(() => {
    const storedId = sessionStorage.getItem('contribution-id');
    if (storedId && storedId === postRef?.current?.id) {
      if (isMobileOnly) {
        const rect = postRef.current.getBoundingClientRect();
        const route = document.getElementById('feed-container');
        route?.scrollTo({ top: rect.top, behavior: 'instant' });
      } else {
        postRef.current.scrollIntoView({ behavior: 'instant' });
      }
      sessionStorage.removeItem('contribution-id');
    }
  }, [postRef]);

  const handleRemoveSingleBookmark = (contributionId, profileId) => {
    removeItemFromCollection([
      {
        collectionID: bookmarkId,
        mentions: [{ contributionId, profileId }],
      },
    ]);
  };

  return (
    <StyledCard
      className={`post mb-2 ${withSelectIndicator ? 'with-select-indicator' : ''} ${
        selected ? 'selected' : ''
      }`}
      ref={postRef}
      id={post?.contributionId}
    >
      <Card.Body className="text-sm p-2">
        <Card className="author-details mb-2">
          <Card.Body className="d-flex p-2">
            <CardHeader
              {...{
                post,
                hideActions,
                menuItems,
                hideFeedPost,
                onAlertUpdate,
                onProfileNavigate,
              }}
            />
            {bookmarkId && (
              <Button
                buttonType="warning"
                onClick={() => handleRemoveSingleBookmark(post?.contributionId, post?.profileId)}
              >
                Delete
              </Button>
            )}
          </Card.Body>
        </Card>
        <PostContent post={post} openContributionDetails={openContributionDetails} />
      </Card.Body>
      {withSelectIndicator ? (
        <IsEditWrapper selected={selected}>
          <span onClick={onClick}>
            <Icon name="tickBox" size={12} />
          </span>
        </IsEditWrapper>
      ) : null}
    </StyledCard>
  );
};

export default Post;

Post.propTypes = {
  post: PropTypes.object.isRequired,
  withSelectIndicator: PropTypes.bool,
  openContributionDetails: PropTypes.func,
  hideActions: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  bookmarkId: PropTypes.string,
  menuItems: PropTypes.array,
  hideFeedPost: PropTypes.bool,
  onAlertUpdate: PropTypes.func,
  onProfileNavigate: PropTypes.func,
};

Post.defaultProps = {
  withSelectIndicator: false,
  openContributionDetails: undefined,
  hideActions: false,
  selected: false,
  onClick: undefined,
  bookmarkId: undefined,
  menuItems: undefined,
  hideFeedPost: false,
  onAlertUpdate: undefined,
  onProfileNavigate: undefined,
};
