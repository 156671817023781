import { useRef } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useHistory } from 'react-router';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import PropTypes from 'prop-types';

import { useFilters } from 'context/filtersContext';
import { useSlideDrawer } from 'context/slideDrawerContext';

import useBootstrapAccordionHook from 'utils/hooks/useBootstrapAccordionHook';

import DropdownSubmenuEmpty from 'common/formControls/DropdownSubmenuEmpty';
import KwelloTooltip from 'common/tooltip/KwelloTooltip';

import styles from './FiltersList.module.scss';

import { SavedSearchTooltipContent } from './SavedSearchTooltipContent';

export const SavedFiltersListMenu = ({ eventKey }) => {
  const { savedSearches, applySavedSearch, currentSavedSearch, removeSavedSearch } = useFilters();
  const { closeSlideDrawer } = useSlideDrawer();
  const history = useHistory();

  const filterRef = useRef();
  useBootstrapAccordionHook({ eventKey, element: filterRef });

  const setSaved = search => {
    if (currentSavedSearch) {
      removeSavedSearch();
      setTimeout(() => applySavedSearch(search), 100);
    } else {
      applySavedSearch(search);
    }

    if (isMobileOnly) {
      history.goBack();
    } else {
      closeSlideDrawer();
    }
  };

  return (
    <div ref={filterRef}>
      {savedSearches?.length > 0 &&
        savedSearches?.map(savedSearch => (
          <div
            key={savedSearch.savedSearchId}
            className={styles['saved-search-list-item']}
            onClick={() => setSaved(savedSearch)}
          >
            {savedSearch.savedSearchName}
            <KwelloTooltip
              position="left"
              key={savedSearch.savedSearchId}
              content={<SavedSearchTooltipContent savedSearch={savedSearch} />}
            >
              <InfoIcon className="ml-2" />
            </KwelloTooltip>
          </div>
        ))}

      {!savedSearches?.length && <DropdownSubmenuEmpty text="No saved searches for this report" />}
    </div>
  );
};

SavedFiltersListMenu.propTypes = { eventKey: PropTypes.number };
SavedFiltersListMenu.defaultProps = { eventKey: undefined };
