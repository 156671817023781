import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { sortBy } from 'lodash';

import { conversationTabFilterLabels, filtersSettings as filterMenu } from 'constants';
import ROUTE from 'constants/route';
import { add, dateFormats, formatDate } from 'utils/dateHelper';
import { useApi } from 'utils/hooks';
import { useApiService } from 'utils/hooks/useApiService';
import useCurrentPage from 'utils/hooks/useCurrentPage';
import resolveFilterId from 'utils/resolveFilterId';

import { toastNotify } from 'common/ToastNotification/ToastNotification';

const FiltersContext = createContext({});

// Default / Initial Filter Values
// if adding a new filter, also add them to savedSearchLabels so that the filter name appears in saved searches
const SocialMonitoringDefaultFilters = {
  projectPeriod: isMobileOnly
    ? { metric: 'day', value: 7, direction: 'backward' }
    : { metric: 'month', value: 12, direction: 'backward' },
  dateRange: undefined,
  search: [],
  state: [],
  country: [],
  sentiment: [],
  source: [],
  category: [],
  diseaseState: [],
  topic: [],
  treatment: [],
  keyword: [],
  domain: [],
  scientificRank: {
    greaterThanOrEqualTo: null,
    lessThanOrEqualTo: null,
  },
  hasTwitterFollowers: [],
  isScientificallyProfiled: [],
  isDigitalOpinionLeader: [],
  segment: [],
  twitterBioSearch: [],
  advancedSearch: '',
  profile: [],
  onTopic: [],
  list: [],
  treatmentsFlag: null,
  followerSpecialties: [],
  interactionSpecialities: [],
  socialRank: null,
  twitterHandle: null,
  author: null,
  mentionedWith: {},
  mentionedBy: {},
  collection: [],
  addedToKwello: null,
};

/**
 * @description HCP Filter List
 */
const hcpFiltersList = [
  'numberOfFollowers',
  'followerSpecialties',
  'interactionSpecialities',
  'follower',
  'followers',
  'isProfiled',
  'generation',
  'mentionTypes',
  'isHCP',
  'profileMention',
];

/**
 * @description Conversations Filter List
 */
const conversationFilterList = ['author', 'twitterHandle'];

const FiltersConfig = {
  kolProfileFilters: {
    projectBased: true,
    url: [ROUTE.socialMonitoringUrl, ROUTE.kolProfile],
    exportUrl: [ROUTE.socialMonitoringUrl, ROUTE.exportKolProfile],
    default: SocialMonitoringDefaultFilters,
    dualFilters: true,
  },
  socialMonitoringFilters: {
    projectBased: true, // Determines if local filters are saved against a project id
    url: [ROUTE.socialMonitoringUrl], // The URL that this filter is used on
    exportUrl: undefined, // The URL that is used to export content to ppt
    defaultFilter: true, // If no URL matches then this filter is used
    default: SocialMonitoringDefaultFilters, // The default filter values
    dualFilters: true, // Any updated to dual filters will be saved to this filter
  },
  gkpFilters: {
    projectBased: true,
    url: [ROUTE.globalKolPlanningUrl],
    exportUrl: undefined,
    default: {
      projectPeriod: undefined,
      dateRange: {
        start: formatDate(new Date(), dateFormats.api),
        end: formatDate(add(new Date(), { years: 2 }), dateFormats.api),
      },
      search: [],
    },
    dualFilters: false,
  },
};

const filterKeys = Object.keys(FiltersConfig);

const FiltersProvider = props => {
  const localFilterName = 'SAVED_FILTERS';
  const [mounted, setMounted] = useState(false);
  const [ready, setReady] = useState(false);
  const { currentPage, currentPageIncludesAll } = useCurrentPage();
  const [filterReferenceElements, setFilterReferenceElements] = useState({});
  const [savedSearches, setSavedSearches] = useState(undefined);
  const [currentSavedSearch, setCurrentSavedSearch] = useState(undefined);
  const [gkpFilterConfig, setGkpFilterConfig] = useState([]);
  const [filterStore, setFilterStore] = useState({});
  const [filterLabels, setFilterLabels] = useState({});
  const previousSavedSearch = useRef();
  const isKolProfile = useRef();
  const { call: gkpFilterReferenceCall, loading: loadingGkpFilterReferences } = useApi();
  const { pathname } = useLocation();
  const { projectId } = useParams();
  const [loadingGkpFilters, setLoadingGkpFilters] = useState(false);
  const [loadingSocialReferences, setLoadingSocialReferences] = useState(false);
  const api = useApiService();
  const profileId = pathname.split('/')[4];

  /**
   * @description - Filters that are NOT displayed in the search bar
   */

  const convertConfigToFilterLabels = config => {
    return config.reduce((obj, current) => {
      return {
        ...obj,
        ...current.items.reduce((itemObj, itemCurrent) => {
          return {
            ...itemObj,
            [itemCurrent.value]: itemCurrent.badgeLabel || itemCurrent.label,
          };
        }, {}),
      };
    }, {});
  };

  useEffect(() => {
    // Populate the filter labels with social monitoring labels
    setFilterLabels(prevState => ({
      ...prevState,
      ...convertConfigToFilterLabels(filterMenu),
      ...conversationTabFilterLabels,
    }));
  }, []);

  // Set initial filters from default in configs
  useEffect(() => {
    setFilterStore({
      ...filterKeys.reduce(
        (filtersObj, current) => ({
          ...filtersObj,
          [current]: FiltersConfig[current].default || {},
        }),
        {},
      ),
    });
  }, []);

  // Set a variable for the current filter based on URL
  const currentFilter = useMemo(() => {
    let filterKey = filterKeys.find(filter => {
      return (
        currentPageIncludesAll(FiltersConfig[filter].url) ||
        currentPageIncludesAll(FiltersConfig[filter].exportUrl)
      );
    });

    if (!filterKey) {
      // If no filter key based on URL, use the default filter
      filterKeys.forEach(filter => {
        if (FiltersConfig[filter].defaultFilter) {
          filterKey = filter;
        }
      });
    }

    return filterKey;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  /**
   * @function filterUpdatedFilters
   * @param {Object} data - Filter object
   * @returns {Object} - Updated filters
   */
  const filterUpdatedFilters = useCallback(
    data =>
      Object.keys(data).reduce((updated, filterName) => {
        if (data[filterName]?.length === 0 || !data[filterName]) {
          return updated;
        }

        if (
          JSON.stringify(data[filterName]) !==
          JSON.stringify(FiltersConfig[currentFilter].default[filterName])
        ) {
          // eslint-disable-next-line no-param-reassign
          updated[filterName] = data[filterName];
        }

        return updated;
      }, {}),
    [currentFilter],
  );

  /**
   * @function saveLocalFilters
   * @description - Save filters to local storage
   */
  const saveLocalFilters = useCallback(
    filters => {
      if (projectId) {
        const currentSavedFilters = JSON.parse(localStorage.getItem(localFilterName)) || {};

        localStorage.setItem(
          localFilterName,
          JSON.stringify({
            ...currentSavedFilters,
            [projectId]: {
              ...filters,
            },
          }),
        );
      }
    },
    [projectId],
  );

  const updateFilterStore = useCallback(
    (data, filter) => {
      setFilterStore(prevState => {
        const newFilters = {
          ...prevState,
          [filter || currentFilter]: {
            ...prevState[filter || currentFilter],
            ...data,
          },
        };

        saveLocalFilters(newFilters);

        return newFilters;
      });
    },
    [currentFilter, saveLocalFilters],
  );

  const defaultFilters = useMemo(() => {
    return FiltersConfig[currentFilter].default || {};
  }, [currentFilter]);

  useEffect(() => {
    isKolProfile.current = currentPage(ROUTE.kolProfile);
  }, [currentPage, pathname]);

  /**
   * @description Contribution Filter List
   */
  const contributionFiltersList = useMemo(
    () => [
      ...(filterMenu
        .find(item => item.header === 'Contribution')
        ?.items?.map(filter => filter.value) || []),
    ],
    [],
  );

  /**
   * @dsescription These filters get saved on Social && KOL Profile Filters
   */
  const dualFilters = useMemo(
    () => ['search', 'advancedSearch', ...contributionFiltersList],
    [contributionFiltersList],
  );

  /**
   * @function resolveFilterBadgeLabel
   * @param {string} filterName
   * @returns {string} - The label for the filter badge
   */
  const resolveFilterBadgeLabel = useCallback(
    filterName => {
      return filterLabels[filterName] || undefined;
    },
    [filterLabels],
  );

  /**
   * @function resolveFilterValue
   * @param {string} filterName
   */
  const resolveFilterValue = useCallback(filterValue => {
    if (filterValue === null) return [];

    if (Array.isArray(filterValue)) {
      return filterValue.map(x => {
        if (typeof x === 'string') return x;
        if (x === true) return 'true';
        if (x === false) return 'false';
        return x?.label || x?.reference;
      });
    }

    if (filterValue?.label || filterValue?.reference) {
      return [filterValue.label || filterValue.reference];
    }

    return filterValue;
  }, []);

  /**
   * @description Current filters based on current page
   * @returns {Object} - Current filters
   */
  const filters = useMemo(() => {
    const remainingFilters = filterStore[currentFilter] || {};
    // Removes all HCP Filters
    const modifiedFilters = Object.keys(remainingFilters).reduce((obj, filter) => {
      if (['author', 'twitterHandle'].includes(filter)) {
        return {
          ...obj,
          [filter]: remainingFilters[filter]?.value || null,
        };
      }

      if (filter === 'projectPeriod') {
        if (pathname.includes(`${ROUTE.socialMonitoringUrl}/${projectId}/${ROUTE.dashboard}`)) {
          return {
            ...obj,
            [filter]: { metric: 'day', value: 14, direction: 'backward' },
          };
        }
      }

      if (filter === 'profile' && currentPage(ROUTE.socialMonitoringUrl) && profileId) {
        return {
          ...obj,
          [filter]: [{ id: profileId }],
        };
      }

      if (!hcpFiltersList.includes(filter)) {
        return {
          ...obj,
          [filter]: remainingFilters[filter],
        };
      }

      return obj;
    }, {});

    if (modifiedFilters.dateRange) {
      if (pathname.includes(`${ROUTE.socialMonitoringUrl}/${projectId}/${ROUTE.dashboard}`)) {
        modifiedFilters.dateRange = undefined;
      } else {
        modifiedFilters.projectPeriod = undefined;
      }
    }

    const sovParams = currentPage(ROUTE.sov) ? { isSov: true } : {};

    return { ...modifiedFilters, projectId, ...sovParams } || {};
  }, [currentFilter, filterStore, pathname, projectId, profileId, currentPage]);

  /**
   * @description HCP FilterstwitterBioSearch
   * @returns {Object} HCP Filters Object
   */
  const hcpFilters = useMemo(() => {
    const currentFilters = filterStore[currentFilter] || {};

    return Object.keys(currentFilters).reduce((obj, filterName) => {
      if (hcpFiltersList.includes(filterName)) {
        if (
          ['twitterHandle', 'author', 'mentionedWith', 'mentionedBy', 'follower'].includes(
            filterName,
          )
        ) {
          return {
            ...obj,
            [filterName]: currentFilters[filterName]?.value,
          };
        }

        return {
          ...obj,
          [filterName]: currentFilters[filterName],
        };
      }

      return obj;
    }, {});
  }, [filterStore, currentFilter]);

  /**
   * @description Conversation Filters
   * @returns {Object} Conversation Filters Object
   */
  const conversationFilters = useMemo(() => {
    const currentFilters = filterStore[currentFilter] || {};

    return Object.keys(currentFilters).reduce((obj, filterName) => {
      if (conversationFilterList.includes(filterName)) {
        return {
          ...obj,
          [filterName]: currentFilters[filterName],
        };
      }

      return obj;
    }, {});
  }, [filterStore, currentFilter]);

  /**
   * @function getFilter
   * @param {String} filterName Filter name required
   * @returns {Object} - Filter value
   */
  const getFilter = useCallback(
    filterName => {
      return filters[filterName] || hcpFilters[filterName];
    },
    [filters, hcpFilters],
  );

  /**
   * @function getRawFilter
   * @param {String} filterName Filter name required
   * @returns {Object} - Filter value
   */
  const getRawFilter = useCallback(
    filterName => {
      return filterStore[currentFilter]?.[filterName];
    },
    [filterStore, currentFilter],
  );

  /**
   * @function setSavedSearchAlert
   * @param {Object} params - { savedSearchIds[], notifFrequency }
   */
  const setSavedSearchAlert = useCallback(
    async ({ savedSearchIds, notifFrequency }) => {
      try {
        const response = await api({
          method: 'POST',
          url: '/userProfile/setSavedSearchAlert',
          data: {
            savedSearchIDs: savedSearchIds,
            frequency: notifFrequency,
            projectId,
          },
        });

        setSavedSearches(previousSearches =>
          previousSearches.map(search => {
            if (response.data.savedSearchIDs.includes(search.savedSearchId)) {
              setCurrentSavedSearch({
                ...search,
                notifFrequency: response.data.frequency,
              });

              return {
                ...search,
                notifFrequency: response.data.frequency,
              };
            }

            return search;
          }),
        );
      } catch (error) {
        if (!axios.isCancel(error)) {
          toastNotify('error', 'Failed to set saved search alert');
        }
      }
    },
    // - api is a function that does not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId],
  );

  /**
   * @function saveSearch
   * @param {Object} params - { savedSearchName, searchId, notifFrequency }
   */
  const saveSearch = useCallback(
    async ({ savedSearchName, savedSearchId, notifFrequency, filter }) => {
      try {
        const response = await api({
          method: 'POST',
          url: '/shared/createUpdateSavedSearch',
          data: {
            savedSearchName,
            savedSearchId,
            filter: filter || filterStore.socialMonitoringFilters,
            notifFrequency,
            projectId,
          },
        });

        if (!savedSearchId) {
          setSavedSearches(prevSavedSearches => [
            ...prevSavedSearches,
            {
              savedSearchId: response.data.savedSearchId,
              savedSearchName: response.data.savedSearchName,
              filter: response.data.filter,
            },
          ]);

          setSavedSearchAlert({ savedSearchIds: [response.data.savedSearchId], notifFrequency });

          toastNotify('success', 'Saved search created successfully');
        } else {
          setSavedSearches(previousSearches =>
            previousSearches.map(search => {
              if (search.savedSearchId === savedSearchId) {
                return {
                  ...search,
                  savedSearchName: response.data.savedSearchName,
                  filter: response.data.filter,
                };
              }

              return search;
            }),
          );
          if (currentSavedSearch) {
            setCurrentSavedSearch({
              ...currentSavedSearch,
              filter: response.data.filter,
            });
          }

          toastNotify('success', 'Saved search updated successfully');
          if (notifFrequency) {
            setSavedSearchAlert({
              savedSearchIds: [savedSearchId],
              notifFrequency,
            });
          }
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          toastNotify('error', 'Failed to save search');
        }
      }
    },
    // - api is a function that does not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterStore, currentSavedSearch, setSavedSearchAlert, projectId],
  );

  /**
   * @function setFilter
   * @param {Object} newFilters Object containing filters to be set
   */
  const setFilter = useCallback(
    async newFilters => {
      const updatedDualFilters = Object.keys(newFilters).filter(newFilterName =>
        dualFilters.includes(newFilterName),
      );

      const values = newFilters;

      const dualFiltersToUpdate =
        (FiltersConfig[currentFilter].dualFilters &&
          updatedDualFilters.length &&
          filterKeys.filter(item => item !== currentFilter && FiltersConfig[item].dualFilters)) ||
        [];

      setFilterStore(prevFilters => {
        const updatedFilters = {
          ...prevFilters,
          ...{
            [currentFilter]: {
              ...filterStore[currentFilter],
              ...values,
            },
            ...dualFiltersToUpdate.reduce((obj, filterName) => {
              return {
                ...obj,
                [filterName]: {
                  ...filterStore[filterName],
                  ...updatedDualFilters.reduce(
                    (dualFilter, current) => ({ ...dualFilter, [current]: values[current] }),
                    {},
                  ),
                },
              };
            }, {}),
          },
        };

        saveLocalFilters(updatedFilters);

        return updatedFilters;
      });
    },
    [currentFilter, filterStore, dualFilters, saveLocalFilters],
  );

  /**
   * @function setAllFilters
   * @param {Object} newFilters Object containing filters to be set
   */
  const setAllFilters = useCallback(
    newFilters => {
      updateFilterStore({
        ...FiltersConfig[currentFilter].default,
        ...newFilters,
      });
    },
    [currentFilter, updateFilterStore],
  );

  /**
   * @function resetFilter
   * @param {String} filterName - Optional Filter name to be reset / Blank to reset all
   */
  const resetFilter = useCallback(
    filterName => {
      if (filterName && FiltersConfig[currentFilter].dualFilters) {
        return setFilter({ [filterName]: FiltersConfig[currentFilter].default[filterName] });
      }

      if (filterName && !FiltersConfig[currentFilter].dualFilters) {
        return setFilterStore(prevState => {
          const tempValues = { ...prevState[currentFilter] };
          delete tempValues[filterName];

          const newFilters = {
            ...prevState,
            [currentFilter]: tempValues,
          };

          saveLocalFilters(newFilters);

          return newFilters;
        });
      }

      return setFilterStore(() => {
        const allDefaultFilters = {
          gkpFilters: FiltersConfig.gkpFilters.default,
          kolProfileFilters: FiltersConfig.kolProfileFilters.default,
          socialMonitoringFilters: FiltersConfig.socialMonitoringFilters.default,
        };

        saveLocalFilters(allDefaultFilters);

        return allDefaultFilters;
      });
    },
    [currentFilter, setFilter, saveLocalFilters],
  );

  /**
   * @description Updated filters
   * @returns {Object} - Filters Object
   */
  const updatedFilters = useMemo(
    () => filterUpdatedFilters(filters),
    [filters, filterUpdatedFilters],
  );

  /**
   * @description Updated filters in an array
   * @returns {Array} - Array of updated filters
   */
  const updatedFiltersArray = useMemo(
    () =>
      Object.keys(updatedFilters).map(filterName => ({
        id: resolveFilterId(filterName),
        key: filterName,
        label: resolveFilterBadgeLabel(filterName),
        filterValues: resolveFilterValue(updatedFilters[filterName]),
      })),
    [updatedFilters, resolveFilterBadgeLabel, resolveFilterValue],
  );

  /**
   * @description On Project Change, restores saved filters
   */
  useEffect(() => {
    if (mounted && projectId) {
      setReady(false);

      const localFilter = JSON.parse(localStorage.getItem(localFilterName))?.[projectId];

      if (localFilter) {
        const newFilters = {
          ...filterKeys.reduce((obj, filterName) => {
            return {
              ...obj,
              [filterName]: {
                ...FiltersConfig[filterName].default,
                ...localFilter[filterName],
              },
            };
          }, {}),
        };

        setFilterStore(newFilters);
      } else {
        const newFilters = {
          ...filterKeys.reduce((obj, filterName) => {
            return {
              ...obj,
              [filterName]: {
                ...FiltersConfig[filterName].default,
              },
            };
          }, {}),
        };

        setFilterStore(newFilters);
      }
      setReady(true);
    } else {
      setMounted(true);
    }
  }, [projectId, mounted, updateFilterStore, currentFilter]);

  /**
   * @function fetchSocialMonitoringFilterReferences
   * @description - Fetches filter reference elements for social monitoring
   */

  const fetchSocialMonitoringFilterReferences = useCallback(async () => {
    setLoadingSocialReferences(true);
    const referenceTypes = ['profile', 'domain', 'topic', 'country', 'keyword', 'diseaseState'];

    try {
      const response = await api({
        method: 'POST',
        url: 'Shared/GetAllFilterOptions',
        data: {
          projectId,
          referenceTypes,
          value: '',
        },
      });

      const selectedKeys = ['filterReferenceElements', 'searchForReference', 'treatment'];
      const filterData = response.data;

      const filteredReferenceOptions = filterData
        ? Object.keys(filterData)
            .filter(key => selectedKeys.includes(key))
            .reduce((obj, key) => {
              // eslint-disable-next-line no-param-reassign
              obj[key] = filterData[key];
              return obj;
            }, {})
        : {};

      const newSearchFilters = {
        ...filteredReferenceOptions?.searchForReference?.reduce((obj, data, index) => {
          return {
            ...obj,
            [referenceTypes[index]]: data.referenceData,
          };
        }, {}),
      };

      const socialFilterReferences = {
        ...filteredReferenceOptions?.filterReferenceElements,
        ...newSearchFilters,
        treatment: filteredReferenceOptions?.treatment,
        lists: sortBy(filterData?.lists, list => list.listName.toLowerCase()),
        collections: sortBy(filterData?.collections, collection =>
          collection.collectionName.toLowerCase(),
        ),
        savedSearches: sortBy(filterData?.savedSearches, savedSearch =>
          savedSearch.savedSearchName.toLowerCase(),
        ),
      };

      setFilterReferenceElements(socialFilterReferences);
      setSavedSearches(socialFilterReferences?.savedSearches);
      setLoadingSocialReferences(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        toastNotify('error', 'Failed to fetch filter reference elements');
        setLoadingSocialReferences(false);
      }
    }

    // - api is a function that does not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  /**
   * @function fetchGkpFilterReferences
   * @description - Fetches filter reference elements for GKP
   */
  const fetchGkpFilterReferences = useCallback(
    async ({ secret }) => {
      setLoadingGkpFilters(true);

      try {
        const response = await gkpFilterReferenceCall({
          method: 'POST',
          url: '/GlobalKOLPlanningSearch/FilterReferenceElements',
          data: {
            key: secret,
          },
        });

        // Generate GKP Filter Config && add to filterReferences
        let gkpFilterReferences = {};

        const modifiedGKP =
          response?.gkpFilters.map(category => {
            const refElements = category.items.reduce((obj, current) => {
              return {
                ...obj,
                [category?.header
                  ? `${category?.header?.toLowerCase()}_${current.value}`
                  : current.value]: current.referenceElements,
              };
            }, {});

            gkpFilterReferences = {
              ...gkpFilterReferences,
              ...refElements,
            };

            return {
              ...category,
              items: category.items.map((item, index) => ({
                ...item,
                id: item.id || `${item.value}-${index}`,
                key: category?.header
                  ? `${category.header?.toLowerCase()}_${item.value}`
                  : `${item.value}-${index}`,
                value: category?.header
                  ? `${category.header?.toLowerCase()}_${item.value}`
                  : item.value,
                referenceElements: undefined,
              })),
            };
          }) || [];

        // set GKP Filter config
        setGkpFilterConfig(modifiedGKP);

        // Generate filter badge labels for GKP
        const gkpLabels = convertConfigToFilterLabels(modifiedGKP);

        setFilterLabels(prevState => ({
          ...prevState,
          ...gkpLabels,
        }));

        setFilterReferenceElements(prevState => ({ ...prevState, ...gkpFilterReferences }));

        setLoadingGkpFilters(false);
      } catch (error) {
        if (!axios.isCancel(error)) {
          toastNotify('error', 'Failed to fetch GKP Filter References');
        }
      }
    },
    [gkpFilterReferenceCall],
  );

  /**
   * @function removeSavedSearch
   */
  const removeSavedSearch = useCallback(() => {
    setCurrentSavedSearch(undefined);
    resetFilter();
  }, [resetFilter]);

  /**
   * @function deleteSavedSearch
   * @param {String[]} savedSearchIds - Array of saved search ids to be deleted
   */
  const deleteSavedSearch = useCallback(
    async savedSearchIds => {
      try {
        await api({
          method: 'POST',
          url: '/userProfile/deleteSavedSearch',
          data: { savedSearchIds, projectId },
        });

        removeSavedSearch();
        setSavedSearches(prevSavedSearches =>
          prevSavedSearches.filter(search => !savedSearchIds.includes(search.savedSearchId)),
        );

        toastNotify(
          'success',
          `Saved search${savedSearchIds.length > 1 ? 'es' : ''} deleted successfully`,
        );
      } catch (error) {
        if (!axios.isCancel(error)) {
          toastNotify('error', 'Failed to delete saved searches');
        }
      }
    },
    // - api is a function that does not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, removeSavedSearch],
  );

  /**
   * @function applySavedSearch
   * @param {Object} search - Saved search to be applied
   */
  const applySavedSearch = useCallback(search => {
    setCurrentSavedSearch({
      ...search,
      filter: {
        ...search.filter,
        dateRange: search.filter.dateRange,
        projectPeriod: {
          ...SocialMonitoringDefaultFilters.projectPeriod,
          ...search.filter.projectPeriod,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (
      currentSavedSearch &&
      (!previousSavedSearch.current ||
        previousSavedSearch?.current?.savedSearchId !== currentSavedSearch.savedSearchId)
    ) {
      setAllFilters(currentSavedSearch.filter);
      previousSavedSearch.current = currentSavedSearch;
    }

    if (!currentSavedSearch) {
      previousSavedSearch.current = undefined;
    }
  }, [currentSavedSearch, setAllFilters]);

  const value = useMemo(
    () => ({
      defaultFilters,
      loadingGkpFilterReferences,
      loadingGkpFilters,
      hiddenFilters: [
        ...hcpFiltersList,
        'dateRange',
        'projectPeriod',
        'followerSpecialities',
        'interactionSpecialities',
        'search',
        'advancedSearch',
        'projectId',
        'isSov',
        'onTopic',
        currentPage(ROUTE.kolProfile) ? 'profile' : undefined,
      ],
      filters,
      hcpFilters,
      conversationFilters,
      updatedFilters,
      updatedFiltersArray,
      savedSearches,
      filterReferenceElements,
      currentSavedSearch,
      gkpFilterConfig,
      ready,
      filterLabels,
      getFilter,
      setFilter,
      setAllFilters,
      resetFilter,
      fetchGkpFilterReferences,
      setFilterReferenceElements,
      saveSearch,
      setSavedSearchAlert,
      applySavedSearch,
      removeSavedSearch,
      deleteSavedSearch,
      resolveFilterBadgeLabel,
      resolveFilterValue,
      getRawFilter,
      SocialMonitoringDefaultFilters,
      setSavedSearches,
      fetchSocialMonitoringFilterReferences,
      loadingSocialReferences,
    }),
    [
      filters,
      hcpFilters,
      conversationFilters,
      updatedFilters,
      updatedFiltersArray,
      savedSearches,
      filterReferenceElements,
      currentSavedSearch,
      ready,
      applySavedSearch,
      removeSavedSearch,
      deleteSavedSearch,
      resolveFilterBadgeLabel,
      resolveFilterValue,
      defaultFilters,
      fetchGkpFilterReferences,
      filterLabels,
      getFilter,
      gkpFilterConfig,
      loadingGkpFilterReferences,
      resetFilter,
      saveSearch,
      setAllFilters,
      setFilterReferenceElements,
      setFilter,
      setSavedSearchAlert,
      getRawFilter,
      loadingGkpFilters,
      currentPage,
      fetchSocialMonitoringFilterReferences,
      loadingSocialReferences,
    ],
  );

  if (!ready) {
    return null;
  }

  return <FiltersContext.Provider value={value} {...props} />;
};

const useFilters = () => useContext(FiltersContext);

export { FiltersProvider, useFilters };
